.app-login{
    padding: 40px 0;
    position: relative;
    height: 100vh;
    overflow: auto ;
    background-color: #f7f7f7;
    &::before{
        content: "";
        width:50%;
        height: 100%;
        background-color: #f8931d;
        position:absolute;
        top:0;
        left:0;
    }
}
.app-login-image{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .app-login-page-logo{
        position: absolute;
        top:0;
        left:0;
    }
    .app-login-image-figure{
        width: 100%;
        object-fit: contain;
    }
}
.h-100{
    height:100%;
}
.app-login-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
}
.app-login-content-heading{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #EFEFEF;
    h5{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #333333;
        margin:0 0 5px 0;
    }
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #6D6D75;
        margin:0;
    }
}
.app-login-content-form{
    margin-top:40px;
    label{
        display: block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #333333;
        margin:0 0 10px 0;
    }
    input{
        background: #FFFFFF;
        box-shadow: 0px 0px 50px #ECECEC;
        border-radius: 5px;
        padding:15px 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #B1B1BC;
        width: 100%;
        max-width: 300px;
        border:0;
        display: flex;
        outline: 0;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
    button.verify-button{
        margin-top:20px;
    }
}
.app-login-description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
    margin-top:10px;
    button{
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color:#FF2528;
        text-transform: unset;
    }
}
.app-login-form-field{
    &:not(:last-child){
        margin-bottom: 20px;
    }
}
.otp-input-fields{
    display: flex;
    margin:10px 0;
    input{
        width: 60px !important;
        height: 50px !important;
        margin-right: 20px;
        padding:0;
    }
}
.resend-button{

}
.app-login-header{
    background-color: #fff;
    box-shadow: 0px 0px 50px #ececec;
    padding:15px 20px 10px 20px;
    position: fixed;
    left:0;
    top:0;
    width: 100%;
    display: none;
}
.app-register-form-field-error{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #FF0000;
    text-transform: unset;
}
@media screen and (max-width: 767px) {
    .app-login-header{
        display: block;
    }
    .hide-on-mobile{
        display: none !important;
    }
    .app-login{
        padding: 40px 0;
        position: relative;
        height: 100vh;
        overflow: hidden;
        background-color: #f7f7f7;
        &::before{
            display: none;
        }
    }
}
