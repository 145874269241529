.SpecialServicesOffers.swiper{
    width: 100%;
    height: 100%;
    .swiper-wrapper{
        padding:0 0 30px 0;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
      }
    .special-services-slider-card{
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding:10px;
        img{
            height: 197px;
            object-fit: cover;
            border-radius: 8px;
        }
        .special-services-slider-card-content{
            display: flex;
            flex-direction: column;
            margin-top:20px;
            h6{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #333333;
                margin: 0 0 6px 0;
                text-align: left;
            }
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #6D6D75;
                margin:0;
                text-align: left;
            }
            span{
                width: fit-content;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                color:#FF2327;
                background:#fff;
                padding:5px;
                border: 1px solid #FF2327;
                border-radius: 4px;
                text-align: left;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev{
        color:#4F5357;
        width:40px;
        height:40px;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        border-radius: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        padding:15px;
        opacity: 1;
        &:after{
            font-size: 16px;
        }
    }
    .swiper-button-next{
        right: 0;
    }
    .swiper-button-prev{
        left: 0;
    }
    .swiper-pagination{
        bottom:0px;
        --swiper-theme-color: #FF2528;
        --swiper-pagination-bullet-inactive-color:var(--swiper-theme-color);
        .swiper-pagination-bullet-active{
            width:20px;
            border-radius: 10px;
        }
    }
  }
