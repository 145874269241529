.app-banner{
    width: 100%;
    position: relative;
}
.app-banner-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h1{
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
    }
    p{
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        opacity: 0.7;
        text-align: left;
        margin: 0;
    }
}
.app-banner-search{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.app-location-search{
    position: relative;
    .app-location-search-icon{
        position:absolute;
        left:17px;
        top:50%;
        transform: translateY(-50%);
        z-index: 99;
    }
    .MuiAutocomplete-root{
        .MuiOutlinedInput-root{
            background-color: #FFFFFF;
            border-radius: 8px 8px 0 0;
            padding:10px 12px 10px 32px;
            .MuiAutocomplete-input{
                font-family: 'Poppins', sans-serif;
                padding:0 8px;
                padding-right: 0;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                color: #333333;
            }
            .MuiAutocomplete-endAdornment{
                .MuiAutocomplete-clearIndicator{
                    background-color: #FFFFFF;
                }
            }
        }
    }
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
        padding-right: 40px !important;
    }
}
.app-banner-search-input{
    width:100%;
    display: flex;
    position: relative;
    img{
        position: absolute;
        left: 18px;
        top: 18px;
    }
    input{
        font-family: 'Poppins', sans-serif;
        border-radius: 0 8px 8px 8px;
        width:100%;
        background: #F5F5F5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding:12px 12px 12px 46px;
        outline: 0;
        border:0;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #333333;
    }
}
.offers-heading{
    display: flex;
    flex-direction: column;
    h1{
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #333333;
        margin: 0 0 5px 0;
    }
    p{
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #6D6D75;
        padding: 0;
        margin: 0;
    }
}
.offers-slider-wrapper{
    // background: #FFFFFF;
    // border: 1px solid #EDEDED;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    // border-radius: 5px;
    // padding:10px 0;
    margin-top:30px;
}
.home-upcmng-services{
    padding: 100px 0 0 0;   
}

.upcoming-services-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    padding:0;
    margin:30px -15px 0 -15px; 
    .upcoming-services-list-item{
        width: calc(20% - 30px);
        margin: 15px 15px;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        .upcoming-services-list-item-icon{
            width:120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom:15px;
            border-radius: 8px;
            img{
                width: 60px;
                display: flex;
                align-items: center;
            }
        }
        .upcoming-services-list-item-title{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #333333;
        }
    }
 }

 .bg-yellowish{
     background-color:#FCF3CC;
 }
 .bg-dark-yellowish{
     background-color:#E4D9A9;
 }
 .bg-pinkish{
     background-color:#FFE8F0;
 }
 .bg-dark-pinkish{
     background-color:#EDD4DD;
 }
 .bg-bluish{
     background-color:#E2F6FF;
 }
 .bg-dark-bluish{
     background-color:#CFE6F1;
 }
 .app-special-product-service{
    padding:100px 0 0 0;
 }
 .app-special-product-service-slider{
    margin-top:30px;
 }
 .app-download-section{
    display: flex;
    padding:100px 0;
    .app-download-section-image{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
            object-fit: contain;
        }
    }
    .app-download-section-info{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .app-download-section-content{
            display: flex;
            flex-direction: column;
            h1{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                color: #333333;
                margin:0 0 10px 0;
                text-align: left;
            }
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                letter-spacing: 0.02em;
                color: #6D6D75;
                opacity: 0.7;
                margin:0;
                text-align: left;
            }
            ul{
                display: flex;
                list-style: none;
                padding:0;
                margin:30px 0 0 0;
                li{
                    display: flex;
                    list-style:none;
                    &:not(:last-child){
                        margin-right: 20px;
                    }
                    img{
                        height:40px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
 }

 @media screen and (max-width:767px) {
    .upcoming-services-list{
        margin: 30px -10px 0 -10px;
        .upcoming-services-list-item{
            width: calc(50% - 20px);
            margin: 15px 10px;
            padding: 15px 10px;
        }
    }
 }
 @media (min-width:767px) and (max-width:991px) {
    .upcoming-services-list{
        margin: 30px -10px 0 -10px;
        justify-content: flex-start;
        .upcoming-services-list-item{
            width: calc(33.33% - 20px);
            margin: 15px 10px;
            padding: 15px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 8px;
        }
    }
 }
.MuiButtonBase-root{
    &.location-button {
      color: #FF6C17;
      min-width: auto;
      img{
          width: 20px;
          height: 20px;
        }
    }
    &.clear-button{
         min-width: auto;
         img{
             width: 16px;
         }
     }
}
  
  .location-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: fit-content;
    padding: 5px;
    width: 100%;
  }
  
  .location-box .location-box-display {
    margin: 0;
    font-size: 14px;
    color: #777777;
    text-align: left;
    width: 100%;
    font-weight: 500;
  }
  .app-profile-edit-wrapper{
    padding: 20px 0 60px 0;
    .app-login-content-form input{
        max-width: unset;
        color: #777777;
    }
    .app-login-content-wrapper{
        max-width: 600px;
        width: 100%;
    }
    .app-login-content{
        align-items: center;
    }
    .app-login-content-heading{
        display: flex;
        align-items: center;
        h5{
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
  }

  .app-profile-edit-image{
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #FF6C17 ;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    &-main{
        object-fit: cover;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    &-icon{
        position: absolute;
        right:-8px;
        bottom: 0;
    }
    &-input{
        display: none;
        width:0;
        height:0;
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }
  }
  .app-profile-edit-button{
    display: flex;
    justify-content: center;
  }

  .app-banner-search-new{
    background-color: rgb(255 255 255 / 90%);
    box-shadow: 0 0 6px #eaeaea;
    padding:15px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    min-width: 500px;
    .react-select-container{
        width: 100%;
        .inner__control{
            &--is-focused{
                border-color: #FF6C17;
                box-shadow: none !important;
            }
        }
    }
  }

  @media screen and (max-width:767px) {
    .d-sm-none{
        display: none !important;
    }
    .app-banner-search-new{
        position: unset;
        transform: unset;
        width: 100%;
        min-width: auto !important;
        padding: 10px;
    }
    .padding-20px{
        padding: 0 30px;
    }
}
@media screen and (max-width:575px) {
    .location-box .location-box-display{
        width: 200px;
    }
}