.checkout-page-wrapper{
    padding:40px 0;
}
.checkout-page-wrapper-heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #333333;
    margin:0 0 20px 0;
}
.checkout-page-address{
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #ECECEC;
    border-radius: 10px;
    padding:20px;
    position: relative;
    h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #333333;
        margin:0 0 10px 0;
    }
    .checkout-page-address-home{
        display: flex;
        align-items: center;
        img{
            width: 18px;
            margin-right: 8px;
        }
        span{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            margin-left: 5px;
        }
    }
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #6D6D75;
        margin:5px 0 0 0;
    }
    .checkout-page-address-edit{
        position: absolute;
        top:20px;
        right:20px;
        background: #FFDEDF;
        border-radius: 5px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FF2528;
        padding:5px 20px;
        &:hover{
            color: #ffffff; 
        }
    }
}
.checkout-order-summary{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    &:not(:last-child){
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 10px;
    }
}
.checkout-order-summary-price{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .checkout-order-summary-price-items{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #FF2327;
    }
    .checkout-order-summary-price-info{
        display: flex;
        align-items: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #333333;
        img{
            margin-right: 4px;
        }
    }
}
.checkout-page-summary{
    margin-top: 20px;
}
.checkout-page-suggestions{
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #ececec;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    margin-top: 20px;
    .checkout-page-suggestions-title{
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #333333;
        margin:0;
    }
    .checkout-page-suggestions-list{
        display: flex;
        justify-content: space-between;
        padding:0;
        margin:20px -15px 0 -15px;
        list-style: none;
        .checkout-page-suggestions-list-item{
            width:calc(33% - 30px);
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 0px 50px #ECECEC;
            border-radius: 10px;
            padding:10px;
            margin:0 15px;
            .app-service-accordian-content-card-image{
                margin-left:0;
            }
            .checkout-page-suggestions-list-item-detail{
                display: flex;
                flex-direction: column;
                margin-top:25px;
                h6{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #333333;
                    margin: 0 0 5px 0;
                }
                p{
                    margin:0;
                    display: flex;
                    align-items: center;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #333333;
                    img{
                        height:12px;
                        margin-right: 2px;
                    }
                }
            }
        }
    }
}
.checkout-payment-sticky{
    position: sticky;
    top:20px;
}
.checkout-payment-summary{
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #ececec;
    border-radius: 10px;
    padding: 20px;
    .checkout-payment-summary-title{
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #333333;
        margin: 0;
    }
}
.checkout-payment-summary-list{
    display: flex;
    flex-direction: column;
    padding:0;
    margin:0;
    list-style: none;
    li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px 0;
        &:not(:last-child){
            border-bottom: 1px solid #F1F1F1;
        }
        h6{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            margin:0;
        }
    }
}
.app-slot-modal-wrapper{
    display: flex;
    flex-direction: column;
    .app-slot-modal-header{
        padding:20px;
        position: relative;
        border-bottom:1px solid #EFEFEF;
        h1{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #333333;
            margin:0;
        }
        button.modal-close-button{
            width:40px;
            min-width: unset;
            height:40px;
            display: flex;
            align-items: center;
            justify-content: center;
            filter: drop-shadow(0px 4px 10px rgba(124, 124, 124, 0.3));
            border: 1px solid #EDEDED;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            border-radius: 50%;
            color:#FF2327;
            position: absolute;
            top:20px;right:20px;
            img{
                width:10px;
            }
        }
    }
    .app-slot-modal-body{
        display: flex;
        flex-direction: column;
        .app-slot-modal-body-description{
            padding:30px 20px;
            h2{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: #333333;
                margin:0 0 5px 0;
            }
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #6D6D75;
                margin:0;
            }
        }
        .app-slot-modal-body-calender{
            background: #FFFFFF;
            box-shadow: 0px 0px 50px #ECECEC;
            border-radius: 10px;
            margin:0 20px;
            padding:15px;
            h4{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #333333;
                margin:0;
            }
            .app-slot-calender-dates{
                padding:0;
                margin:10px 0;
                list-style: none;
                display: flex;
                justify-content: center;
                max-width: 100%;
                overflow-x: auto;
                li{
                    margin: 10px;
                    button.calender-dates-button{
                        border: 1px solid #F1F1F1;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        min-width: unset;
                        padding:10px 15px;
                        color:#FF2528;
                        &:hover{
                            background-color: rgb(255 101 24 / 7%);
                        }
                        &.calender-dates-button-selected{
                            background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
                            span{
                                color:#fff;
                            }
                            &:hover{
                                background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
                                span{
                                    color:#fff;
                                }
                            }
                        }
                        span{
                            color: #6D6D75;
                            font-size: 14px;
                        }
                    }
                }
            }
            .app-slot-calender-time-slot{
                padding:0;
                margin:0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                li{
                    width:calc(33% - 30px);
                    margin:10px 15px;
                    button.slot-time-button{
                        border: 1px solid #F1F1F1;
                        border-radius: 5px;
                        padding:10px;
                        width:100%;
                        color:#6D6D75;
                        &:hover{
                            background-color: rgb(255 101 24 / 7%);
                        }
                        &.slot-time-button-selected{
                            background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
                            color:#fff;
                            &:hover{
                                background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
                            }
                        }
                    }
                }
            }
        }
    }
}
.slot-order-button{
    display: flex;
    justify-content: center;
    padding:15px 0;
}

.app-schedule-select-date-list-item-active,
.app-schedule-select-timeslot-list-item-active{
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
}
.app-schedule-select-date-list-item-active span,
.app-schedule-select-timeslot-list-item-active span{
    color: #ffffff;
}

@media screen and (max-width: 767px) {
    .checkout-page-suggestions-list{
        flex-wrap: wrap !important;
        .checkout-page-suggestions-list-item{
            width:calc(50% - 30px) !important;
            margin-bottom:20px !important;
        }
    }
    .checkout-page-address {
        display: flex;
        flex-direction: column;
      }
      .checkout-page-address-edit {
        margin-top: 20px;
      }
}

.login-inp{
    width:100%;
    background: #FFFFFF;
    border: 1px solid #9898A7;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding:15px 20px;
    outline: 0;
    color: var(--text-black);
    margin-top: 10px;
}
.inp-ext-append .login-inp{
    padding-left: 38px;
}

.app-history-card-info.selected {
    /* Apply your desired styles for the selected state */
    background-color: whitesmoke;
    cursor: pointer;
    /* Add a tick mark or any other visual indicator */
  }

.checkout-order-summary-info  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D75;
    margin:5px 0 0 0;
}

@media screen and (max-width:767px){
    .checkout-page-address .checkout-page-address-edit{
        position: unset;
        margin:0 !important;
        top: unset;
        right: unset;
        margin:5px 0 !important;
    }
    .app-service-accordian-content-card-rating{
        flex-direction: column;
    }
}