.service-detail-header{
    box-shadow: 0px 0px 50px #ececec;
}
.servicedetail-wrapper{
    padding:60px 0;
    display: flex;
    flex-direction: column;
}
.servicedetail-heading{
    display: flex;
    flex-direction: column;
    h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #333333;
        margin:0 0 5px 0;
        text-align: left;
    }
    ul{
        display: flex;
        flex-direction: column;
        padding:0;
        margin:0;
        list-style: none;
        li{
            display: flex;
            align-items: center;
            margin:3px 0;
            img{
                margin-right: 8px;
            }
            span{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                color: #333333;
            }
        }
    }
}
.servicedetail-offers-slider{
    padding: 50px 0 0 0;
}
.app-service-accordian-content-card{
    display: flex;
    background-color: #fff;
    padding-bottom:15px;
    justify-content: space-between;
    &:not(:last-child){
        border-bottom: 1px solid #EFEFEF;
    }
    &:not(:first-child){
        padding-top:15px;
    }
}
.app-service-accordian-group{
    .MuiAccordion-root.MuiAccordion-rounded{
        background: #FFFFFF;
        box-shadow: 0px 0px 50px #ECECEC;
        border-radius: 10px;
        &:not(:last-child){
           margin-bottom: 15px;
        }
        &:not(:first-child){
            margin-top: 15px;
        }
        &:before{
            display: none;
        }
    }
}
.app-service-accordian-title{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    padding:0;
    margin:0;
    text-align: left;
}
.app-service-accordian-content-card-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    margin: 0 0 5px 0;
    padding:0;
    text-align: left;
}
.app-service-accordian-content-card-price{
    display: flex;
    align-items: center;
    margin: 0;
}
.app-service-accordian-content-card-price img{
    display: flex;
    margin-right: 2px;
    height:13px;
}
.app-service-accordian-content-card-price span{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
}
.app-service-accordian-content-card-rating{
    padding:0;
    margin:5px 0 0 0;
    list-style:none;
    display: flex;
}
.app-service-accordian-content-card-rating li{
    display: flex;
    align-items: center;
    padding:0;
    margin-bottom:6px;
    position: relative;
    &:not(:last-child){
        margin-right: 15px;
    }
}
.app-service-accordian-content-card-rating li img{
    margin-right: 5px;
}
.app-service-accordian-content-card-rating li span{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D75;
}
.app-service-accordian-content-card-para{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D75;
    padding:0;
    margin: 10px 0 0 0;
    text-align: left;
}
.app-service-accordian-content-card-image{
    margin-left: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    height: 120px;
}
.app-service-accordian-content-card-image img.app-service-accordian-content-card-image-cnt{
    width:120px;
    min-width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #eaeaea;
}
.MuiButton-root.app-service-add-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 18px;
    filter: drop-shadow(0px 4px 10px rgba(124, 124, 124, 0.3));
    width: 90px;
    min-width: 90px;
    height: 36px;
    padding:8px 15px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FF2528;
    &:hover{
        background: #f5f5f5;
    }
}
.app-service-add{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom:-17px;
}
.app-service-accordian-content-card-para span{
    color: #FF2528;
}
.add-items-count-wrapper{
    width: 90px;
    height: 36px;
    border-radius: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:5px;
    filter: drop-shadow(0px 4px 10px rgba(124, 124, 124, 0.3));
}
.add-items-show-input-alt{
    width: 20px;
    height: 20px;
    text-align: center !important; 
    background: transparent;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #FF2528;
    display: flex;
    justify-content: center;
    align-items: center;
    border:0;
    outline: 0;
    box-shadow: none;
    margin:0 7px;
    padding:0;
}
.add-items-trigger-inn.MuiButton-root{
    width:20px;
    height:20px;
    min-width: unset;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
    box-shadow: none;
    padding:5px;
    color: #FF2528;
}
.service-promotions-wrapper{
    display: flex;
    flex-direction: column;   
}
.service-promotions-list{
    display: flex;
    flex-direction: column;
    list-style: none;
    padding:15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #ECECEC;
    border-radius: 10px;
    margin:0;
    li{
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #E2E2FF;
        border-radius: 10px;
        padding:10px 15px;
        &:not(:first-child){
            margin-top: 10px;
        }
        img{
            width: 40px;
            height: 40px;
            margin-right:15px;
        }
        .service-promotions-info{
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            h6{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #333333;
                margin:0;
                text-align: left;
            }
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                color: #6D6D75;
                margin:0;
                text-align: left;
            }
        }
    }
}
.service-addto-cart{
    background: #FFFFFF;
    border: 1px solid #E2E2FF;
    border-radius: 10px;
    padding:10px 20px;
    display: flex;
    justify-content: space-between;
    h6{
        display: flex;
        align-items: center;
        margin:0;
        img{
            display: flex;
            margin-right: 2px;
            height: 13px;
        }
        span{
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #333333;
        }
    }
}
.services-related-items{
    margin-top: 60px;
}
.servicedetail-info{
    margin-top:60px;
}