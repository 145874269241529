

.login-inp{
    width:100%;
    background: #FFFFFF;
    border: 1px solid #9898A7;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding:15px 20px;
    outline: 0;
    color: var(--text-black);
    margin-top: 10px;
}
.inp-ext-append .login-inp{
    padding-left: 38px;
}