.app-history-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding:15px 0;
    background-color:  #f7f7f7;
}
.app-history-card{
    width: 100%;
    padding:20px;
    margin:5px 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    position: relative;
}
.mt-10{
    margin-top: 10px !important;
}
.history-status-badge{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    position: absolute;
    top:20px;
    right:20px;
    height: 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}
.history-status-completed-badge{
    background: #f8f8f8;
    color: #949494;
}
.history-status-canceled-badge{
    background: #FFEDED;
    color: #E2191D;
}
.app-history-card-profile{
    width: calc(100% - 100px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.app-history-card-profile-img{
    width:50px;
    height:50px;
    border-radius: 50%;
    object-fit: cover;
}
.app-history-card-profile-cnt{
    margin-left:10px;
    display: flex;
    flex-direction: column;
}
.app-history-card-profile-cnt h3{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding:0;
    margin:0;
}
.app-history-card-profile-cnt p{
    padding:0;
    margin:0;
    display: flex;
    align-items: center;
}
.app-history-card-profile-cnt p img{
    margin-right: 6px;
}
.app-history-card-profile-cnt p span{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D75;
}
.app-history-card-info{
    // width: 100%;
    // display: flex;
    align-items: center;
    // justify-content: space-between;
}
.app-history-card-info-cnt{
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
}
.app-history-card-info-cnt h2{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    padding: 0;
    margin: 0 0 7px 0;
}
.app-history-card-info-cnt p{
    display: flex;
    align-items: center;
    padding: 0;
    margin:0;
}
.app-history-card-info-cnt p img{
  margin-right: 10px;
}
.app-history-card-info-cnt p span{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D75;
}
.app-history-card-info-price{
    display: flex;
    align-items: center;
    padding:0;
    margin: 0;
}
.app-history-card-info-price img{ 
    width: 11px;
    height: 17px;
    margin-right: 5px;
    object-fit: cover;
}
.app-history-card-info-price span{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #333333;
    padding:0;
    margin: 0;
}