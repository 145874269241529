.app-header{
    background-color: #ffffff;
}
.logout-btn.MuiButtonBase-root{
    background: rgb(255 0 0 / 10%);
    color: #ff0000;
}
.app-header-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    > a{
        display: flex;
    }
    img{
        object-fit: contain;
    }
    ul{
        display: flex;
        align-items: center;
        list-style: none;
        padding:0;
        margin:0;
        li{
            display: flex;
            &:not(:first-child){
                margin-left: 30px;
            }
            > a{
                display: flex;
            }
        }
    }
}
button.MuiButton-containedPrimary{
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
}
button.MuiButton-containedPrimary:hover{
    background: linear-gradient(270deg, #ff5d00 0%, #fd161a 100%);
}
button.MuiButton-contained{
    font-family: 'Poppins', sans-serif;
    border-radius: 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    padding:10px 20px;
    text-transform: unset;
}
button.button-text-black.MuiButton-textPrimary{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #111213;
    text-transform: unset;
}
button.button-text-black.MuiButton-textPrimary:hover{
    color: #FF6C17;
}
.menu-icon{
    width: 25px !important;
    margin:0 !important;
}
.nvbar-brand{
    width: 90px;
}
.cart-icon{
    position: relative;    
    height: 100%;
    display: flex;

    img{
        width: 25px;
    }
    span{
        position: absolute;
        top: -2px;
        right: -8px;
        background: #ff3124;
        font-size: 10px;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 11px;
        color: #fff;
        font-weight: 600;
        border-radius: 50%;
    }
}

.profile-pic{
    width:35px;
    height:35px;
    border-radius: 50%;
    object-fit: cover !important;
    cursor: pointer;
    margin:0 !important;
}