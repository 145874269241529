.services-wrapper{
    padding:30px 0 0 0;
    display: flex;
    flex-direction: column;
}
.services-wrapper-content{
    .services-tabs-main{
        .MuiTabs-flexContainer{
            overflow-x: auto;
            max-width: 100%;
        }
        .MuiTabs-scroller{
            justify-content: center;
            display: flex;
        }
        .services-tabs-label{
            height: 47px;
            background: #FCF6F6;
            border-radius: 50px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            color: #333333;
            text-transform: unset;
            margin:0 15px;
            padding:10px 20px;
            &.Mui-selected{
                background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
                color:#ffffff;
            }
        }
        .MuiTabs-indicator{
            display: none !important;
        }
    }
}
a{
    text-decoration: none;
}