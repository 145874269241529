.OffersSwiper.swiper{
    width: 100%;
    height: 100%;
    .swiper-wrapper{
        padding:0 0 30px 0;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
      }
    .offers-slider-card{
        display: flex;
        padding:10px;
        position: relative;
        border-radius: 10px;
        img{
            width: 272px;
            height: 134px;
            object-fit: cover;
            border-radius: 10px;
        }
        .offers-slider-card-content{
            width: 40%;
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right:10px;
            display: flex;
            flex-direction: column;
            h6{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                color: #000000;
                text-align: left;
                margin:0 0 5px 0;
            }
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                color: #000000;
                margin:0 0 10px 0;
                text-align: left;
            }
            span{
                width: fit-content;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                color:#FF2327;
                background:#fff;
                padding:5px;
                border: 1px solid #FF2327;
                border-radius: 4px;
                text-align: left;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev{
        color:#4F5357;
        width:40px;
        height:40px;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        border-radius: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        padding:15px;
        opacity: 1;
        &:after{
            font-size: 16px;
        }
    }
    .swiper-button-next{
        right: 0;
    }
    .swiper-button-prev{
        left: 0;
    }
    .swiper-pagination{
        bottom:0px;
        --swiper-theme-color: #FF2528;
        --swiper-pagination-bullet-inactive-color:var(--swiper-theme-color);
        .swiper-pagination-bullet-active{
            width:20px;
            border-radius: 10px;
        }
    }
  }
