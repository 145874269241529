@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Italic.eot');
    src: url('SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Italic.woff2') format('woff2'),
        url('SegoeUI-Italic.woff') format('woff'),
        url('SegoeUI-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Bold.eot');
    src: url('SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Bold.woff2') format('woff2'),
        url('SegoeUI-Bold.woff') format('woff'),
        url('SegoeUI-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI.eot');
    src: url('SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI.woff2') format('woff2'),
        url('SegoeUI.woff') format('woff'),
        url('SegoeUI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-BoldItalic.eot');
    src: url('SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-BoldItalic.woff2') format('woff2'),
        url('SegoeUI-BoldItalic.woff') format('woff'),
        url('SegoeUI-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

