.app-footer{
    background: #222222;
    padding:30px 0 0 0;
    .app-footer-brand{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #FDFDFD;
            margin:10px 0 0 0;
            text-align: left;
        }
    }
    .app-footer-quicklink{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h3{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            display: flex;
            align-items: center;
            color: #FDFDFD;
            margin:0 0 10px 0;
        }
        ul{
            display: flex;
            flex-direction: column;
            padding:0;
            margin:0;
            list-style: none;
            li{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #FDFDFD;
                text-align: left;
                margin: 4px 0;
                display: flex;
                align-items: center;
                img{
                    margin-right:8px;
                }
            }
        }
    }
    .app-footer-bottom{
        border-top: 1px solid #FDFDFD;
        padding:20px 0;
        margin-top: 30px;
        p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            display: flex;
            justify-content: center;
            text-align: center;
            color: #FDFDFD;
            margin:0;
        }
    }
 }