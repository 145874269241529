@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qahiri&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700&display=swap');
  
@import url('../../fonts/Segoe/stylesheet.css');
@import url('../../fonts/objectivity/objectivity-font.css');
body{
    font-family: 'Poppins', sans-serif !important;
}
html{
    scroll-behavior: smooth;
}
*{
    box-sizing: border-box;
}
.app-phpne-no{
    a{
        display: flex;
        align-items: center;
        img{
            width: 18px;
        }
        span{
            color: #FF6C17;
            font-weight: 500;
            margin-left: 8px;
        }
    }
}
.header-nav-text{
    a{
        color: #333333;
        &:hover,
        &:focus{
            color: #FF6C17;
        }
    }
}
.login-btn-icon-sm{
    display: none;
}


.see-more-btn{
 .MuiButtonBase-root{
    img{
        width: 22px;
    }
 }   
}
.calltonumber{
    display: flex;
    align-items: center;
    img {
        width: 18px;
    }
    span{
        color: #FF6C17;
        font-weight: 500;
        margin-left: 8px;
    }
}
@media (min-width: 768px){
    .see-more-btn{
        display: none !important;
    }
}
@media screen and (max-width: 767px){
    .login-btn-icon-sm{
        display: block;
        width: 18px;
    }   
}


.app-new-landing-banner{
    width: 100%;
    background-image: url('../../../public/images/landing/landing-screen.png');
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px 0px;
}
.landing-form-box{
    width: 500px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 3.13329px 62.66578px 0px rgba(0, 0, 0, 0.05);
    padding: 40px 30px;
    position: relative;
}
.discover-colors{
    padding-top: 120px;
}
.discover-colors h2{
    font-family: 'Qahiri';
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size:70px;
    font-style: normal;
    font-weight: 600;
    line-height:50px;
    margin: 0;
    width: 270px
}
.discover-main-buttons{
    margin-top: 80px;
}
.discover-buttons .vew-our-services{
    font-size: 15px;
    line-height: 25px;
    padding: 15px 30px;
    color: #FFF;
    font-family: 'Objectivity';
    font-weight: 500;
    border-radius: 7.833px;
    border: 0.783px solid #FF6C17;
    box-shadow: 0px 0px 15.66645px 0px rgba(0, 0, 0, 0.05);
    text-align: center;
}
.discover-buttons .call-us{
    font-size: 15px;
    line-height: 25px;
    font-family: 'Objectivity';
    font-weight: 500;
    border-radius: 7.833px;
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    box-shadow: 0px 0px 15.66645px 0px rgba(0, 0, 0, 0.05);
    padding: 15px 60px;
    border: unset;
    color: #FFFF;
    margin-left: 5px;
    text-align: center;
}
.discover-buttons .explore-cta{
    font-size: 14px;
    line-height: 22px;
    font-family: 'Objectivity';
    font-weight: 500;
    border-radius: 7.833px;
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    box-shadow: 0px 0px 15.66645px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 15px;
    border: unset;
    color: #FFFF;
    margin-left: 5px;
    text-align: center;
}
.app-new-landing-banner-content{
    height: calc(100vh - 78px);
    display: flex;
    align-items: center;
    padding: 50px 0px 20px 0px;
}
.landing-form-box-content h2{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size:25px;
    font-style: normal;
    font-weight: 700;
    line-height:35px;
    margin: 0px 0px 10px 0px;
}
.app-new-landing-banner-content p{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size:16px;
    font-style: normal;
    font-weight: 500;
    line-height:26px;
    margin: 0px 0px 0px 0px;
}
.forms{
    margin: 30px 0px 0px 0px;
}
.form-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.form-group label{
    color: #7E91AE;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height:22px;
    margin: 0px 0px 5px 0px;
}
.form-group input{
    padding:10px;
    border-radius: 4.7px;
    border: 0.783px solid #FF6C17;
    background: #FFF;
    height: 40px;
}
.form-group input::placeholder{
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height:22px;
    color: #000;
}
.check-box{
    display: flex;
    align-items: center;
}
.check-box label{
    color: #242424;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height:22px; 
    margin: 0;
}
.check-box input{
    border-radius: 4px;
    border: 1.998px solid #FF6C17;
}
.enquire-button{
    margin-top: 40px;
    display: flex;
    justify-content: center ;
}
.enquire-button button{
    font-size: 13px;
    line-height: 23px;
    font-family: 'Objectivity';
    border-radius: 7.833px;
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    box-shadow: 0px 0px 15.66645px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 20px;
    cursor: pointer;
    border: unset;
    color: #FFFF;
}
.how-we-work,
.what-we-offer{
    margin-top: 90px;
}
.how-we-work-content,
.why-we-are-best-content,
.what-we-offer-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.how-we-work-content h2,
.why-we-are-best-content h2,
.what-we-offer-content h2{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size:40px;
    font-style: normal;
    font-weight: 400;
    line-height:50px;
    margin: 0px  0px 10px 0px;
}
.how-we-work-content p,
.why-we-are-best-content p,
.what-we-offer-content p{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size:16px;
    font-style: normal;
    font-weight: 400;
    line-height:26px;
    margin: 0;
    width: 550px;
}
.how-we-work-boxs{
    margin-top: 180px;
}
.design-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.design-box-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.design-box-content h5{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height:30px;
    margin: 02px 0px 5px 0px;
}
.design-box-content p{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height:25px;
    width: 400px;
    margin: 0px 0px 0px 0px;
}
.design-box-1{
    position: relative;
}
.design-box-1::after{
    content: '';
    position: absolute;
    background-image: url('../../how-we-work/line-1.png');
    width:100%;
    height:120px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: -50px;
    right: -350px;
}
.design-box-2{
    margin-top: 90px;
}
.design-box-2 .design-box-content p{
    position: relative;
}
.design-box-2 .design-box-content p::after{
    content: '';
    position: absolute;
    background-image: url('../../how-we-work/line-2.png');
    width:100%;
    height:130px;
    background-repeat: no-repeat;
    background-size: contain;
    bottom:-140px;
    right:0px;
}
.design-box-3{
    margin-top: 40px;
    position: relative;
}
// .design-box-3::before{
//     content: '';
//     position: absolute;
//     background-image: url('../../how-we-work/line-3.png');
//     width:100%;
//     height:490px;
//     background-repeat: no-repeat;
//     background-size: contain;
//     top: 60px;
//     left:-140px;
// }
.why-we-are-best{
    margin-top: 80px;
}
.why-we-are-best-content-2{
    width: 100%;
    display: flex;
    align-items: center;
}
.why-we-are-best-content-2:not(:first-child){
    margin-top: 50px;
}
.why-we-are-best-mini-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    position: relative;
}
.why-we-are-best-mini-content::before{
    content: '';
    position: absolute;
    width: 231px;
    height: 10px;
    background-image: url('../../../src//how-we-work/linear-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    bottom:-21px;
    left: 0px;
}
.why-we-are-best-mini-content h3{
    color: #242424;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height:30px;
    margin: 0px 0px 10px 0px;
}
.why-we-are-best-mini-content-2 h3{
    position: relative;
}
.why-we-are-best-mini-content-2 h3::after{
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-image: url('../../../src/how-we-work/star.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 8px;
    right: -20px;
}
.why-we-are-best-mini-content p{
    color: #242424;
    font-family: 'Inter';
    font-size:13px;
    font-style: normal;
    font-weight: 700;
    line-height:26px;
    margin: 0px 0px 0px 0px;
    text-align: left;
}
.why-we-are-best-image img{
    width: 100%;
    height: 330px;
}
.why-we-are-best-boxs{
    margin-top: 80px;
}
.what-we-offer-tabs{
    margin-top: 40px;
}
.what-we-offer-tabs .MuiTabs-flexContainer{
    justify-content: center;
}
.what-we-offer-tabs .MuiTabs-flexContainer .Mui-selected{
    border-radius: 7.833px;
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    box-shadow: 0px 0px 15.66645px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 90px;
    font-size: 12px;
    max-width: 0;
    min-height: 0;
    min-width: 0;
    color: #FFF !important;
    font-family: 'Objectivity';
    white-space: nowrap;
}
.what-we-offer-tabs .MuiTabs-flexContainer button{
    color: #FF2327;
    font-size: 12px;
    line-height: 22px;
    font-family: 'Objectivity';
}
.what-we-offer-tabs .MuiBox-root{
    border-bottom: unset;
}
.what-we-offer-tabs .MuiTabs-indicator{
    background-color: unset;
}
.painting-content h4{
    color: #0A1629;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height:30px;
    margin: 05px 0px 05px 0px;
}
.painting-content p{
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    color: #7E7F88;
    width: 280px;
}
.painting-box-1{
    margin-bottom: 90px;
}
.painting-images{
    margin-top: 60px;
}
// .linear-bg-what-we-offer{
//     position: absolute;
//     bottom: -90px;
// }
.linear-bg-what-we-offer img{
    width: 100%;
}
.key-services{
    margin-top: 80px;
}
.key-services-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.key-services-content h2{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size:40px;
    font-style: normal;
    font-weight: 400;
    line-height:50px;
    margin: 0px  0px 10px 0px;
}
.key-services-content p{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size:16px;
    font-style: normal;
    font-weight: 400;
    line-height:26px;
    margin: 0;
    width: 550px;
}
.key-services-images{
    margin-top: 70px;
}
.roof-watering-image img{
    width: 100%;
}
.key-services-image-content h2{
    color: #1E1E1E;
    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height:40px;
    margin: 0px 0px 5px 0px;
}
.key-services-image-content p{
    color: #585858;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height:26px;
    margin: 0;
}
.book-now{
    margin-top: 30px;
}
.book-now a{
    color: #FFF;
    text-align: center;
    font-family: 'Objectivity';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-radius: 7px;
    padding: 10px 30px;
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    box-shadow: 0px 0px 15.67901px 0px rgba(0, 0, 0, 0.05);
}
.best-waterproofing,
.testimonial{
    margin-top: 80px;
}
.best-waterproofing-content,
.testimonial-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.best-waterproofing-content h2,
.testimonial-content h2{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size:40px;
    font-style: normal;
    font-weight: 400;
    line-height:50px;
    margin: 0px  0px 10px 0px;
}
.best-waterproofing-content p,
.testimonial-content p{
    color: #020F14;
    text-align: center;
    font-family: 'Inter';
    font-size:16px;
    font-style: normal;
    font-weight: 400;
    line-height:26px;
    margin: 0;
    width: 550px;
}
.best-waterproofing-images{
    margin-top: 70px;
}
.experience-expertise{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
.experience-expertise-image{
    display: flex;
    align-items: center;
}
.experience-expertise-content{
    margin-left: 40px;
}
.experience-expertise-content h3{
    color: #1E1E1E;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height:30px;
    margin: 0px 0px 5px 0px;
}
.experience-expertise-content p{
    color: #020F14;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height:26px;
    margin: 0;
    width: 380px;
}
.testimonial-boxs{
    margin: 80px 0px 0px 0px;
}

.testimonial-box{
    border-radius: 12.79px;
    background: #FFF;
    border: 1px solid #ddd;
    padding: 40px 20px;
    height: 100%;
}
.testimonial-box-1{
    margin-top: -40px;
}
.testimonial-box h3{
    color: #000;
    font-family: 'Raleway';
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 35px;
    margin: 0 0 10px 0;
}
.testimonial-box p{
    color: #202B38;
    font-family: 'Segoe UI';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin: 0 0 0 0;
}
.testimonial-box .person-info{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}
.testimonial-box .person-info .link{
    position: absolute;
    right: 0;

}
.testimonial-box .person-info h5{
    color: #202B38;
    font-family: 'Segoe UI';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin: 0 0 0 0;
}
.testimonial-box .person-info h6{
    color: #202B38;
    font-family: 'Segoe UI';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 0 0;
}
.testimonial-box .person-info p{
    color: #202B38;
    font-family: 'Segoe UI';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 0 0 0 0;
}
.testimonial-box .person-info-mini{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.view-all{
    margin: 80px 0px;
    display: flex;
    justify-content: center;
}
.view-all Button{
    color: #FFF;
    text-align: center;
    font-family: 'Objectivity';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-radius: 7px;
    padding: 10px 30px;
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    box-shadow: 0px 0px 15.67901px 0px rgba(0, 0, 0, 0.05);
}
.privacy_policy{
    background-color: #113d4e;
    width: 100%;
}
.privacy_policy_content{
    padding: 120px 0px;
}
.privacy_policy_content h1{
    color: #FFF;
    font-family: 'Objectivity';
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    margin: 0;
}
.privacy_policy_main{
    margin: 50px 0px;
}
.privacy_policy_main h4{
    font-size: 20px;
    line-height: 30px;
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px 0px 0px 0px;
}
.privacy_policy_main p{
    font-size: 16px;
    line-height: 26px;
    font-family: 'Objectivity';
    font-weight: 500;
    margin: 0px 0px 10px 0px;
}
.privacy_policy_main ul li{
    margin-bottom: 10px;
}
.app-new-form-box{
    margin: 40px 0px;
}
.main-form-box{
    padding: 20px 10px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
    border-radius: 20px;
}
.form-box-content{
    width: 100%;
    display: flex;
    align-items: center;
}
.form-box-content .box-1{
    width: 25%;
    display: flex;
    flex-direction: column;
}
.form-box-content .box-1:not(:first-child){
    margin-left: 10px;
}
.form-box-content .box-1:not(:last-child){
    border-right: 1px solid #7E7F88;
}
.form-box-content .box-1 label{
    color: #020F14;
    font-size: 16px;
    line-height:26px;
    font-family: 'Objectivity';
    font-weight: 500;
    margin: 0;
}
.form-box-content .box-1 input{
    border: unset;
    height: 30px;
    font-size: 13px;
    line-height:23px;
    font-family: 'Objectivity';
    font-weight: 400;
    color: #7E7F88;
    background-color: unset !important;
}
.form-box-content .box-1 input::placeholder{
    font-size: 13px;
    line-height:23px;
    font-family: 'Objectivity';
    font-weight: 400;
    color: #7E7F88;
    background-color: unset !important;
}
.form-box-content .box-1 input:-internal-autofill-selected{
    background-color: unset !important;
}
.form-box-content .box-1 input:focus-visible {
    outline: unset;
}
.form-box-content .box-1 .discover-buttons a{
    margin-left: 0 !important;
    padding: 15px 10px;
    font-size: 12px;
}
.form-box-content .box-1 .discover-box-buttons .app-new-call-us{
    white-space: nowrap;
}
.min-app-new-form-box{
    display: flex;
    justify-content: center;
    margin: 40px;
}
.app-new-form-box-2 .form-box-content .box-1{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mini-form-box{
    width: 660px;
}
.discover-box-buttons .box-1 .discover-buttons a{
    padding: 15px 60px;
}

@media (min-width:320px) and (max-width:767px) {
    .discover-buttons .vew-our-services{
        margin-bottom: 10px;
    }
    .app-header-content ul li:not(:first-child){
        margin-left: 25px;
    }
    .discover-colors{
        padding-top:0px;
    }
    .discover-colors h2{
        font-size:40px;
        text-align: center;
        width: auto;
    }
    .check-box{
        display: flex;
        align-items: flex-start;
    }
    .landing-form-box{
        width: 100%;
    }
    .how-we-work-content p,
     .why-we-are-best-content p,
      .what-we-offer-content p{
        font-size: 15px;
        width: auto;
    }
    .landing-form-box-content h2{
        font-size: 20px;
        line-height: 30px;
    }
    .app-new-landing-banner{
        height: unset;
        padding: 50px 0px;
    }
    .app-new-landing-banner-content{
        height: unset;
        padding: 40px 0px;
    }
    .how-we-work,
     .why-we-are-best,
      .what-we-offer{
        margin: 40px 0px 0px 0px;
    }
    .how-we-work-content h2,
     .why-we-are-best-content h2,
      .what-we-offer-content h2{
        font-size: 30px;
        line-height: 40px;
    }
    .how-we-work-boxs,
    .design-box-2,
    .design-box-3{
        margin-top: 40px;
    }
    .discover-buttons{
        display: flex;
        flex-direction: column;
    }
    .discover-main-buttons{
        margin: 30px;
    }
    .design-box-content p{
        width: auto;
    }
    .design-box-content h5{
        font-size: 18px;
    }
    .testimonial-box-1{
        margin: 0;
    }
    .testimonial-box .stars{
        width: 100%;
    }
    .why-we-are-best-boxs{
        margin-top: 40px;
    }
    .design-box-content{
        margin-top: 10px;
    }
    .why-we-are-best-content-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .why-we-are-best-mini-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .why-we-are-best-mini-content::before{
        width: 0px;
    }
    .why-we-are-best-mini-content h3{
        text-align: center;
    }
    .why-we-are-best-content-2:not(:first-child){
        margin-top: 0px;
    }
    .painting-content{
        margin-top: 10px;
    }
    .painting-content h4{
        font-size: 18px;
        text-align: center;
        margin: 0;
    }
    .painting-content p{
        text-align: center;
        width: auto;
    }
    .painting-box-1{
        margin-bottom: 40px;
    }
    .painting-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .key-services{
        margin-top: 40px;
    }
    .key-services-content h2{
        font-size: 30px;
        line-height: 40px;
    }
    .key-services-content p{
        width: auto;
    }
    .design-box img{
        width: 100%;
    }
    .key-services-images{
        margin-top: 40px;
    }
    .key-services-image-content h2{
        font-size: 25px;
        line-height: 35px;
        text-align: center;
    }
    .key-services-image-content p {
        font-size: 15px;
        text-align: center;
    }
    .book-now{
        display: flex;
        justify-content: center;
    }
    .key-services-image-content{
        margin-bottom: 30px;
    }
    .best-waterproofing,
     .testimonial{
        margin-top: 40px;
    }
    .best-waterproofing-content h2,
    .testimonial-content h2{
        font-size: 30px;
        line-height: 40px;
    }
    .best-waterproofing-content p,
     .testimonial-content p{
        width: auto;
    }
    .painting-images{
        margin-top: 30px;
    }
    .what-we-offer-tabs .MuiTabs-flexContainer{
        justify-content: space-around;
    }
    .what-we-offer-tabs .MuiTabs-flexContainer .Mui-selected{
        padding: 10px 65px;
    }
    .what-we-offer-tabs .MuiTabs-flexContainer button{
        font-size: 11px;
    }
    .experience-expertise{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }
    .experience-expertise-image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .experience-expertise-content h3{
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .experience-expertise-content p{
        width: auto;
        text-align: center;
    }
    .experience-expertise-content{
        margin: 0;
    }
    .view-all{
        margin: 40px 0px;
    }
    .view-all Button{
        padding: 10px 20px;
    }
    .testimonial-box h3{
        font-size: 20px;
        line-height: 30px;
    }
    .main-key-services{
        flex-direction: column-reverse !important;
        display: flex !important;
    }
    .design-box-1::after,
    .design-box-2 .design-box-content p::after,
    .design-box-3::before{
        width:0;
    }
    .privacy_policy_content{
        padding: 80px 0px;
    }
    .privacy_policy_content h1{
        font-size: 30px;
        line-height: 40px;
    }
    .privacy_policy_main{
        margin: 30px 0px;
    }
    .privacy_policy_main h4{
        font-size: 18px;
        line-height: 28px;
    }
    .privacy_policy_main p{
        font-size: 15px;
        line-height: 25px;
        margin: 0px 0px 5px 0px;
    }
    .privacy_policy_main ul{
        margin: 05px 0px 0px 0px;
    }
    .form-box-content{
        display: flex;
        flex-direction: column;
    }
    .form-box-content .box-1{
        width: 100%;
    }
    .form-box-content .box-1:not(:last-child){
        border-right: 0px;
        border-bottom: 1px solid #7E7F88;
    }
    .form-box-content .box-1:not(:first-child){
        margin: 10px 0px 0px 0px;
    }
    .discover-box-buttons .box-1 .discover-buttons a{
        white-space: nowrap;
    }
    .discover-box-buttons .box-1:not(:last-child){
            border: unset !important;
    }
    .discover-buttons .call-us{
        margin: 5px 0px 0px 0px;
    }
   
}
@media (min-width:768px) and (max-width:991px) {
   
    .discover-colors{
        padding-top:70px;
    }
    .discover-colors h2{
        font-size:40px;
        width: auto;
    }

    .landing-form-box{
        width: 100%;
    }
    .how-we-work-content p,
     .why-we-are-best-content p,
      .what-we-offer-content p{
        font-size: 15px;
        width: auto;
    }
    .landing-form-box-content h2{
        font-size: 20px;
        line-height: 30px;
    }
    .app-new-landing-banner{
        height: unset;
        padding: 50px 0px;
    }
    .app-new-landing-banner-content{
        height: unset;
        padding: 40px 0px;
    }
    .how-we-work,
     .why-we-are-best,
      .what-we-offer{
        margin: 40px 0px 0px 0px;
    }
    .how-we-work-content h2,
     .why-we-are-best-content h2,
      .what-we-offer-content h2{
        font-size: 30px;
        line-height: 40px;
    }
    .how-we-work-boxs,
    .design-box-2,
    .design-box-3{
        margin-top: 40px;
    }
    .design-box-content p{
        width: auto;
    }
    .design-box-content{
        margin-top: 10px;
    }
    .design-box-content h5{
        font-size: 18px;
    }
    .why-we-are-best-boxs{
        margin-top: 40px;
    }
    .why-we-are-best-content-2{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .why-we-are-best-mini-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .why-we-are-best-mini-content h3{
        text-align: center;
    }
    .why-we-are-best-content-2:not(:first-child){
        margin-top: 0px;
    }
    .why-we-are-best-image{
        margin-top: 0px;
    }
    .painting-content h4{
        font-size: 18px;
        margin: 0;
        text-align: center;
    }
    .painting-content p{
        width: auto;
        text-align: center;
    }
    .painting-box-1{
        margin-bottom: 40px;
    }
    .painting-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .key-services{
        margin-top: 40px;
    }
    .key-services-content h2{
        font-size: 30px;
        line-height: 40px;
    }
    .key-services-content p{
        width: auto;
    }
    .key-services-images{
        margin-top: 40px;
    }
    .key-services-image-content h2{
        font-size: 25px;
        line-height: 35px;
    }
    .key-services-image-content{
        margin-bottom: 30px;
    }
    .best-waterproofing,
     .testimonial{
        margin-top: 40px;
    }
    .best-waterproofing-content h2,
    .testimonial-content h2{
        font-size: 30px;
        line-height: 40px;
    }
    .best-waterproofing-content p,
     .testimonial-content p{
        width: auto;
    }
    .experience-expertise{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }
    .experience-expertise-image{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .experience-expertise-content h3{
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }
    .experience-expertise-content p{
        width: auto;
        text-align: center;
    }
    .experience-expertise-content{
        margin: 0;
    }
    .view-all{
        margin: 40px 0px;
    }
    .view-all Button{
        padding: 10px 20px;
    }
    .testimonial-box h3{
        font-size: 20px;
        line-height: 30px;
    }
    .testimonial-box-1{
        margin: 0;
    }
    .main-key-services{
        flex-direction: column-reverse;
        display: flex;
    }
    .main-key-services{
        flex-direction: column-reverse !important;
        display: flex !important;
    }
    .design-box-1::after,
    .design-box-2 .design-box-content p::after,
    .design-box-3::before{
        width:0;
    }
    .privacy_policy_content{
        padding: 80px 0px;
    }
    .privacy_policy_content h1{
        font-size: 30px;
        line-height: 40px;
    }
    .privacy_policy_main{
        margin: 30px 0px;
    }
    .privacy_policy_main h4{
        font-size: 18px;
        line-height: 28px;
    }
    .privacy_policy_main p{
        font-size: 15px;
        line-height: 25px;
        margin: 0px 0px 5px 0px;
    }
    .privacy_policy_main ul{
        margin: 05px 0px 0px 0px;
    }
    
}
@media (min-width:768px) and (max-width:900px) {
    .why-we-are-best-mini-content::before{
        width: 240px;
        left: 80px;
    }
    .why-we-are-best-content-2:not(:first-child){
        margin-top: 20px !important;
    }
    .why-we-are-best-image{
        margin-top: 30px;
    }
}
@media (min-width:768px) and (max-width:1080px){
    .form-box-content{
        display: flex;
        flex-direction: column;
    }
    .form-box-content .box-1{
        width: 100%;
    }
    .form-box-content .box-1:not(:last-child){
        border-right: 0;
        border-bottom: 1px solid #7E7F88;
    }
    .form-box-content .box-1:not(:first-child){
        margin: 20px 0px 0px 0px;
    }
    .discover-box-buttons .box-1 .discover-buttons a{
        white-space: nowrap;
    }
    .mini-form-box{
        width: 100%;
    }
    .discover-box-buttons{
        display: flex;
        flex-direction: unset;
    }
    .discover-box-buttons .box-1:not(:last-child){
        border-right: 1px solid #7E7F88;
        border-bottom:0;
    }
    .discover-box-buttons .box-1:not(:first-child){
        margin: 0px 0px 0px 0px !important;
    }
}
@media (min-width:992px) and (max-width:1200px) {
    .experience-expertise-content {
        margin-left: 20px;
    }
    .experience-expertise-content p {
        width: auto;
    }
    // .design-box-3::before {
    //     height: 402px;
    //     top: 90px;
    // }

}
@media (min-width:900px) and (max-width:991px) {
    .why-we-are-best-mini-content p{
        font-size: 11px;
    }
    .why-we-are-best-mini-content:not(:first-child){
        margin-left: 20px;
    }
    .why-we-are-best-mini-content::before{
        width: 180px;
    }
    .why-we-are-best-content-2:not(:first-child){
        margin-top: 20px !important;
    }
}

.app-btn-disabled{
    &.Mui-disabled{
        &.MuiButtonBase-root{
            background: #c5c5c5 !important;
            cursor: no-drop;
            color: #777777;
            pointer-events: none;
            user-select: none;
        }
    }
}
.form-submit-dialog .MuiDialog-container > .MuiPaper-root{
    border-radius: 20px;
}
.form-submit-dialog-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-submit-dialog-content img{
    width: 80px;
}
.form-submit-dialog-content h3{
    text-align: center;
    margin-bottom: 30px;
}
.landing-form-box-content .form-group select{
    padding: 10px;
    border-radius: 4.7px;
    border: 0.783px solid #FF6C17;
    background: #FFF;
    height: 40px;
}
.box-1 select{
    border:0 !important;
    outline: 0 !important;
    margin-right: 8px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}