.app-history-detail{
    --background:  #f7f7f7;
}
.app-history-detail-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding:15px 20px;
}
.app-history-detail-optns{
    padding:0;
    margin:0 0 20px 0;
    display: flex;
    justify-content: flex-end;
    list-style: none;
}
.app-history-detail-optns li{
    padding:0;
    margin:0 0 0 20px;
    display: flex;
    align-items: center;
    padding:2px;
    border-radius: 2px;
}
.app-history-detail-optns li p{
    display: flex;
    align-items: center;
    padding:0;
    margin:0;
}
.app-history-detail-opt-help p img{
    margin-right: 6px;
}
.app-history-detail-opt-help p span{
    color: #828282;
}
.app-history-detail-opt-sos p span{
    color: var(--ion-color-primary);
}
.app-history-detail-optns li p span{
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.app-history-detail-noti-card{
    width:100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #ECECEC;
    border-radius: 10px;
    padding:20px 10px;
    position: relative;
    margin-bottom: 20px;
}
.app-history-detail-noti-close{
    width:36px;
    height: 36px;
    background: #FFDEDF;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 10px rgba(124, 124, 124, 0.3));
    position: absolute;
    top:20px;
    right:10px;
}
.app-history-detail-noti-card-cnt{
    display: flex;
    flex-direction: column;
}
.app-history-detail-noti-card-cnt h2{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--text-black);
    padding:0;
    margin:0;
}
.app-history-detail-noti-card-cnt p span{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D75;
}
.app-history-detail-noti-card-cnt p img {
    margin-right: 10px;
}
.app-history-detail-noti-card-cnt p {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}
.app-history-detail-noti-card-btn{
    background: linear-gradient(270deg, #FF6C17 0%, #FF2327 100%);
    border-radius: 50px;
    width: 130px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
    align-self: flex-end;
}
.app-history-detail-booking-card{
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #ECECEC;
    border-radius: 10px;
    padding:20px 10px;
    display: flex;
    flex-direction: column;
}
.app-history-detail-booking-card-title{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
    padding:0;
    margin:0;
}

.app-history-detail-booking-list{
    margin: 5px 0 0 0;
    padding:0;
    display: flex;
    flex-direction: column;
    list-style: none;
}

.app-history-detail-booking-list-item{
    display: flex;
    align-items: center;
    list-style: none;
    margin:  0;
    padding:15px 0;
    border-bottom: 1px solid #F1F1F1;
}
.app-history-detail-booking-list-item:last-child{
    padding-bottom:0;
    border-bottom:0;
}
.app-history-detail-booking-list-info{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}
.app-history-detail-booking-list-info h2{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--text-black);
    padding:0;
    margin:0;
}
.app-history-detail-booking-list-info p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D75;
    padding:0;
    margin:0;
}
.app-history-detail-booking-list-item-home{
    align-self: flex-start;
}
.app-history-detail-booking-list-item-home{
    align-self: flex-start;
}
.app-checkout-edit-badge{
    width: 50px;
    height: 23px;
    background: #FFDEDF;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--ion-color-primary);
    position: absolute;
    top:20px;
    right:10px;
}
.mt-20{
    margin-top: 20px;
}
.d-flex{
    display: flex;
}
.flex-column{
    flex-direction: column;
}
.p-0{
    padding:0 !important;
}
.app-address-divide{
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    height: 100%;
}