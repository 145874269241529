
.services-tab-content-wrapper{
    margin-top: 50px;
    &,.services-tab-content-heading{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        h1{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: #333333;
            margin:0 0 5px 0;
        }
        p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #6D6D75;
            padding:0;
            margin: 0;
        }
    }
}
.services-tab-content-list{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width:100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px #ECECEC;
    border-radius: 10px;
    padding:30px 8px;
    .services-tab-content-list-item{
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        border-radius: 5px;
        width: calc(14.23% - 24px);
        height:150px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:12px;
        a{
            width:100%;
            height:100%; 
        }
        button{
            flex-direction: column;
            width:100%;
            height:100%;
            .MuiTouchRipple-root{
                color:#FF6C17;
            }
            &:hover{
                background-color: rgb(255 62 33 / 5%);
            }
            img{
                width:50px;
                height:50px;
            }
            .services-tab-content-list-item-title{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #333333;
                text-transform: capitalize;
                margin-top:10px;
            }
        }
    }
}
@media (min-width:768px) and (max-width:1024px) {
    .services-tab-content-list .services-tab-content-list-item {
        width: calc(20% - 24px);
    }
}
@media screen and (max-width:474px) {
    .services-tab-content-list .services-tab-content-list-item {
        width: calc(33% - 10px);
        height: auto;
        margin: 10px 5px;
    }
}
@media (min-width:475px) and (max-width:767px) {
    .services-tab-content-list .services-tab-content-list-item {
        width: calc(33% - 24px);
    }
}